import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"

// importing layout
import { Main } from "../layouts/main"

// Importing components
import FeaturedPost from "../components/featured-post"

// Importing styles
import "./styles/blog.scss"

// Importing Bootstrap components
import { Button, Card, Container, Row } from "react-bootstrap"

export default function Blog() {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      allWpPost(
        filter: { tags: { nodes: { elemMatch: { name: { ne: "featured" } } } } }
      ) {
        edges {
          node {
            content
            excerpt
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            title
            slug
          }
        }
      }
    }
  `)

  // Wordpress data
  const { allWpPost } = data
  return (
    <Main>
      <FeaturedPost />
      <Container className="blog-feed">
        {allWpPost.edges.map((post) => {
          return (
            <>
              <Card>
                {post.node.featuredImage ? (
                  <GatsbyImage
                    className="post-image"
                    image={
                      post.node.featuredImage.node.localFile.childImageSharp
                        .gatsbyImageData
                    }
                    alt={post.node.featuredImage.node.altText}
                  />
                ) : (
                  <StaticImage
                    className="post-image"
                    src="../assets/images/logo.png"
                    alt="Elevated Life placeholder image."
                    placeholder="tracedSVG"
                  />
                )}
                <Card.Body>
                  <h1 className="shout">{post.node.title}</h1>
                  <div
                    className="talk"
                    dangerouslySetInnerHTML={{ __html: post.node.excerpt }}
                  />
                  <Link to={post.node.slug}>
                    <Button variant="primary">Read More</Button>
                  </Link>
                </Card.Body>
              </Card>
            </>
          )
        })}
      </Container>
    </Main>
  )
}
